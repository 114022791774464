import { browser } from "$app/environment";
import type { FacetExpandedState, OrderSearchRequest } from "$lib/components/facetpanel/types";
import { initOrderSearchRequest } from "$lib/service/OrderService";
import type { SelectOption } from "$lib/types";
import type { Navigation } from "@sveltejs/kit";
import { writable } from "svelte/store";


const defaultMarketplacePageNumber = 1;
function getInitialMarketplacePageNumber() {
    if (browser) {
        const marketplacePageNumberString = window.sessionStorage.getItem('marketplacePageNumber')
        if (marketplacePageNumberString) {
            const marketplacePageNumber = parseInt(marketplacePageNumberString)
            if (!isNaN(marketplacePageNumber)) {
                return marketplacePageNumber
            }
        }
    }
    return defaultMarketplacePageNumber
}

export const marketplacePageNumber = writable<number>(getInitialMarketplacePageNumber());

marketplacePageNumber.subscribe((value) => {
    if (browser) {
        window.sessionStorage.setItem('marketplacePageNumber', value.toString());
    }
});

export const marketplaceSortOrder = localStorageJSONStore<SelectOption>('marketplaceSortOrder', {
    value: '-created_at',
    label: 'Recently Listed'
})
function localStorageJSONStore<T>(localStorageKey: string, defaultValue: T) {
    function getInitialLocalStorageJSON<T>(key: string, defaultValue: T) {
        if (browser) {
            const marketplaceSortOrderJSON = window.sessionStorage.getItem(key);
            if (marketplaceSortOrderJSON) {
                return JSON.parse(marketplaceSortOrderJSON) as T;
            }
        }
        return defaultValue;
    }

    const localStorageStore = writable<T>(getInitialLocalStorageJSON<T>(localStorageKey, defaultValue));
    localStorageStore.subscribe((value) => {
        if (browser) {
            window.sessionStorage.setItem(localStorageKey, JSON.stringify(value));
        }
    });
    return localStorageStore;
}

export const marketplaceQuery = localStorageJSONStore<OrderSearchRequest>('marketplaceQuery', initOrderSearchRequest())

export const facetsExpanded = localStorageJSONStore<FacetExpandedState>('facetsExpanded', {
    clazz: false,
    color: false,
    elemenet: false,
    foundertank: false,
    material: false,
    network: false,
    price: false,
    race: false,
    type: false
})

export function cleanUpMarketplaceSessionStates(nav: Navigation | null) {
    if (nav?.to?.url.pathname === "/" || nav?.to?.url.pathname.startsWith("/user")) {
        window.sessionStorage.removeItem('facetsExpanded');
        window.sessionStorage.removeItem('marketplaceQuery');
        window.sessionStorage.removeItem('marketplaceSortOrder');
        window.sessionStorage.removeItem('marketplacePageNumber');
    }
}



